<template>
  <TableBase :selectable="selectable">
    <template #header>
      <TableHeaderItem
        class="text-left col-span-6"
        :class="[selectable ? 'md:col-span-3' : 'md:col-span-5']"
        :text="'Employee'"
        :firstItem="!selectable"
      />
      <TableHeaderItem
        class="col-span-4 md:col-span-3 text-right"
        :class="[selectable ? 'hidden md:table-cell' : '']"
        :text="'Wokring hours'"
      />
      <TableHeaderItem class="col-span-3 hidden md:table-cell text-right" :text="'Role'" />
      <TableHeaderItem class="col-span-2 md:col-span-1" />
    </template>
    <template #body>
      <EmployeeTableRow
        v-for="employee in employees"
        :key="employee.id"
        :employee="employee"
        :selectable="selectable"
        :selected="selectedIdList.includes(employee.id)"
        @selected="onSelected"
      />
    </template>
  </TableBase>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
// components
import EmployeeTableRow from "./EmployeeTableRow.vue";
import TableBase from "@/components/table/TableBase.vue";
import TableHeaderItem from "@/components/table/TableHeaderItem.vue";
// other
import { Users } from "@/graphql/types";

export default defineComponent({
  name: "EmployeeTable",
  components: {
    EmployeeTableRow,
    TableBase,
    TableHeaderItem
  },
  props: {
    selectable: { type: Boolean, default: false },
    employees: { type: Object as PropType<Users[]>, required: true },
    selectedIdList: {
      required: false,
      default: () => [] // error if instead "type: Array"
    }
  },
  emits: ["selected"],
  setup(props, context) {
    // pass through event
    const onSelected = (employeeId: string) => {
      context.emit("selected", employeeId);
    };

    return { onSelected };
  }
});
</script>
