
import { defineComponent, PropType } from "vue";
// components
import EmployeeTableRowMenu from "./EmployeeTableRowMenu.vue";
import EmployeeAvatar from "@/features/employees/EmployeeAvatar.vue";
import TableRow from "@/components/table/TableRow.vue";
import TableRowItem from "@/components/table/TableRowItem.vue";
// other
import { Users } from "@/graphql/types";
import { createFullName } from "@/store/useEmployeeStore";
import { useRouter } from "vue-router";
import { cleanUuid } from "@/utils/globalHelpers";

export default defineComponent({
  name: "EmployeeTableRow",
  components: {
    EmployeeTableRowMenu,
    EmployeeAvatar,
    TableRow,
    TableRowItem
  },
  props: {
    employee: { type: Object as PropType<Users>, required: true },
    selectable: { type: Boolean, default: false },
    selected: { type: Boolean, default: false }
  },
  emits: ["selected"],
  setup(props, context) {
    const router = useRouter();

    const onShowDetails = () => {
      const employeeId = cleanUuid(props.employee.id);
      router.push({ path: `/employees/${employeeId}/profile` });
    };

    // click name field
    const onClick = () => {
      if (props.selectable) context.emit("selected", props.employee.id);
      else onShowDetails();
    };

    return { onShowDetails, onClick, createFullName };
  }
});
