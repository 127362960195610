<template>
  <TableRow :selectable="selectable" :selected="selected" @selected="onClick" class="grid-cols-12">
    <TableRowItem
      class="cursor-pointer col-span-6"
      :class="[selectable ? 'md:col-span-3' : 'md:col-span-5']"
      :firstItem="!selectable"
      @click="onClick"
    >
      <div class="flex">
        <a class="flex items-center space-x-3">
          <EmployeeAvatar :text="employee.abbreviation" :color="employee.color" :size="'sm'" />
          <span class="truncate hover:text-gray-600">
            {{ createFullName(employee.first_name, employee.last_name) }}
          </span>
        </a>
      </div>
    </TableRowItem>

    <TableRowItem
      v-if="employee.contracts.length > 0"
      class="col-span-4 md:col-span-3 text-right"
      :class="[selectable ? 'hidden md:table-cell' : '']"
      :content="employee.contracts[0].weekly_working_hours"
    />
    <TableRowItem class="col-span-3 hidden md:table-cell text-right" :content="employee.roles[0].name" />
    <TableRowItem class="col-span-2 md:col-span-1 flex justify-center"
      ><EmployeeTableRowMenu :employee="employee"
    /></TableRowItem>
  </TableRow>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
// components
import EmployeeTableRowMenu from "./EmployeeTableRowMenu.vue";
import EmployeeAvatar from "@/features/employees/EmployeeAvatar.vue";
import TableRow from "@/components/table/TableRow.vue";
import TableRowItem from "@/components/table/TableRowItem.vue";
// other
import { Users } from "@/graphql/types";
import { createFullName } from "@/store/useEmployeeStore";
import { useRouter } from "vue-router";
import { cleanUuid } from "@/utils/globalHelpers";

export default defineComponent({
  name: "EmployeeTableRow",
  components: {
    EmployeeTableRowMenu,
    EmployeeAvatar,
    TableRow,
    TableRowItem
  },
  props: {
    employee: { type: Object as PropType<Users>, required: true },
    selectable: { type: Boolean, default: false },
    selected: { type: Boolean, default: false }
  },
  emits: ["selected"],
  setup(props, context) {
    const router = useRouter();

    const onShowDetails = () => {
      const employeeId = cleanUuid(props.employee.id);
      router.push({ path: `/employees/${employeeId}/profile` });
    };

    // click name field
    const onClick = () => {
      if (props.selectable) context.emit("selected", props.employee.id);
      else onShowDetails();
    };

    return { onShowDetails, onClick, createFullName };
  }
});
</script>
