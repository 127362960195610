
import { defineComponent, PropType } from "vue";
// components
import EmployeeDeleteButton from "@/features/employees/EmployeeDeleteButton.vue";
import TableRowMenu from "@/components/table/TableRowMenu.vue";
import TableRowMenuItem from "@/components/table/TableRowMenuItem.vue";
// other
import { Users } from "@/graphql/types";
import { cleanUuid } from "@/utils/globalHelpers";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "EmployeeTableItemMenu",
  components: {
    EmployeeDeleteButton,
    TableRowMenu,
    TableRowMenuItem
  },
  props: {
    employee: { type: Object as PropType<Users>, required: true }
  },
  setup(props) {
    // navigation
    const router = useRouter();

    // click edit
    const onEdit = () => {
      const employeeId = cleanUuid(props.employee.id);
      router.push({ path: `/employees/${employeeId}/profile` });
    };

    return { onEdit };
  }
});
